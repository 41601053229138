<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>订单详情</div>
                </div>

                <div class="admin_main_block_right">
                    <div><el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button></div>
                </div>
            </div>

            <div class="admin_form_main">
                <div class="order_info">
                    <div class="order_info_block">
                        <el-row>
                            <el-col :span="8"><div>订单号：<font color="#999">{{info.F_VR_DINGDH}}</font></div></el-col>
                            <el-col :span="8"><div>买家留言：<font color="#999">{{info.F_TE_MAIJLY}}</font></div></el-col>
                        </el-row>
                    </div>
                </div>



                <div class="order_goods_list">
                    <el-table :data="content" >
                        &lt;!&ndash; <el-table-column prop="id" label="#"  width="70px"></el-table-column> &ndash;&gt;
                        <el-table-column prop="F_VR_JIANJ" label="名称"></el-table-column>
                        <el-table-column prop="F_VR_TAOCMC" label="套餐"></el-table-column>
                        <el-table-column prop="F_IN_TAOCJG" label="价格"></el-table-column>
                        <el-table-column prop="F_IN_GMSL" label="购买数量"></el-table-column>
                    </el-table>
                    <div class="order_info_right_price">
                        总计：￥ {{info.F_VR_ZONGJ}}
                    </div>
                </div>
            </div>




        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          info:{},
          content:[]
      };
    },
    watch: {},
    computed: {},
    methods: {
        get_order_info:function(){
            this.$post(this.$api.getXiangcscddInfo,{id:this.$route.params.id}).then(res=>{
                this.info = res.data;
                console.log(res.data.F_TE_ZAIXBM)
                console.log(typeof(res.data.F_TE_ZAIXBM))
                this.content = JSON.parse(res.data.F_TE_DINGDMX);
               // console.log(this.contnet)
            });
        }
    },
    created() {
        this.get_order_info();
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>
.order_info{
    font-size: 14px;
}
.order_info_block{
    padding-bottom: 15px;
    margin-bottom: 15px;
    // border-bottom: 1px solid #efefef;
}
.order_info_right_price{
    text-align: right;
    color:#ca151e;
    padding:20px 20px 40px 20px;
    span{
        color:#999;
        font-size: 12px;
        margin-left: 20px;
    }
}
.no_freight{
    line-height: 60px;
    text-align: center;
    font-size: 14px;
    color:#999;
}
</style>